@if(isMobile){
<nav class="navbar bg-body-tertiary p-0 d-flex justify-content-center">
  <div>
    <button class="navbar-toggler bg-white border-0" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
      <svg-icon src="assets/images/icons/side-nav.svg" />
    </button>
    <div class="offcanvas offcanvas-end w-100" tabindex="-1" id="offcanvasNavbar"
      aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarLabel"></h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <ng-container [ngTemplateOutlet]="navigation"></ng-container>

        </ul>

      </div>
    </div>
  </div>
</nav>
}@else {
<div class="side-menu-container bg-white shadow-sm pt-md-5" *ngIf="canUseTheSystem">
  <ul class="nav flex-column px-2">
    <ng-container [ngTemplateOutlet]="navigation"></ng-container>
  </ul>
</div>
}


<ng-template #navigation>

  <li class="nav-item sideNavigation text-center  ">
    <a class="d-block d-lg-none border-bottom pb-3" href="#">
      <img src="/assets/images/logos/Full_Color_Black.png" class="d-inline-block align-top logo-brand" height="100rem"
        alt="">
    </a>
  </li>
  <li class="nav-item sideNavigation">
    <a class="nav-link" [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
      <span>
        <i class="bi bi-graph-up-arrow me-2"></i>
        {{'general.dashboard' | translate}}
      </span>
    </a>
  </li>

  @if(requestManagementItems.length > 0){
  <app-side-menu-dropdown label="requestManagement.title" icon="bi bi-clipboard-check"
    [items]="requestManagementItems" />
  }

  @if(hasPropertyAdsPermission){
  <li class="nav-item sideNavigation">
    <a class="nav-link" [routerLink]="['/advertising/property/list']" [routerLinkActive]="'active'">
      <span>
        <i class="bi bi-megaphone me-2"></i>
        {{'property.propertyAds' | translate}}
      </span>
    </a>
  </li>
  }

  @if (hasClientProfilePermission) {
  <li class="nav-item sideNavigation">
    <a class="nav-link" [routerLink]="['/clients/profile/list']" [routerLinkActive]="'active'">
      <span>
        <i class="bi bi-person-gear me-2"></i>
        {{'clientManagement.clientProfiles' | translate}}
      </span>
    </a>
  </li>
  }

  @if(hasTeamManagementPermission){
  <li class="nav-item sideNavigation">
    <a class="nav-link" [routerLink]="['/team-management/owned-team/team']" [routerLinkActive]="'active'">
      <span>
        <i class="bi bi-people me-2"></i>
        {{'teams.ownedTeam' | translate}}
      </span>
    </a>
  </li>
  }

  @if(isMobile){
  <li class="nav-item sideNavigation">
    <app-switch-language-button />

  </li>
  }


</ng-template>