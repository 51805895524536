import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { HttpBackend, HttpClient, HttpClientModule, HttpRequest, provideHttpClient, withInterceptors } from '@angular/common/http';
import { BASE_PATH } from '../openapi';
import { provideToastr } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { BlockUIModule } from 'ng-block-ui';
import { authInterceptor, errorInterceptor } from './shared/public.api';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { JwtModule } from '@auth0/angular-jwt';
import { tokenGetter } from './app.functions';
import { QuillModule } from 'ngx-quill'
import { HttpLoaderFactory, initializeApp } from '../../../shared/src/lib/shared-function';
import { CAPTCHA_KEY, FILE_ALLOWED_DOCS } from '../../../shared/src/lib/injectable-tokens';
import { ConfigService } from '../../../shared/src/lib/services/config.service';
import { DatePipe } from '@angular/common';



export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([authInterceptor, errorInterceptor])),
    provideAnimations(),
    provideAngularSvgIcon(),
    provideToastr({
      toastClass: 'toastr-style ngx-toastr',
      positionClass: 'toast-top-center',
      progressBar: true,
      progressAnimation: 'increasing',
      timeOut: 5000

    }),
    JwtModule.forRoot({ config: { tokenGetter: tokenGetter, }, }).providers!,
    TranslateModule.forRoot({
      defaultLanguage: 'ar',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }).providers!,
    {
      provide: BASE_PATH,
      useFactory: (configService: ConfigService) => configService.getApi(),
      deps: [ConfigService],
    },
    {
      provide: FILE_ALLOWED_DOCS,
      useFactory: (configService: ConfigService) => configService.getFilAllowedDocs(),
      deps: [ConfigService],
    },
    {
      provide: CAPTCHA_KEY,
      useFactory: (configService: ConfigService) => configService.getCaptchaKey(),
      deps: [ConfigService],
    },
    importProvidersFrom([
      QuillModule.forRoot({
        modules: {
          syntax: false,
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'direction': 'rtl' }],
            ['clean'],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          ],
          keyboard: {
            bindings: {
              indent: {
                key: 'Tab',
                handler() {
                  return false;
                },
              },
              outdent: {
                key: 'Tab',
                shiftKey: true,
                handler() {
                  return false;
                },
              },
            }
          }
        }
      }),
      HttpClientModule,
      BlockUIModule.forRoot({
        delayStart: 1,
        delayStop: 500
      }),
      BlockUIHttpModule.forRoot({
        blockAllRequestsInProgress: true,
        requestFilters: [(req: HttpRequest<any>): boolean => {
          const excludedUrls = ['assets', 'configuration'];

          // Check if the request URL starts with any of the excluded URLs
          const hideLoader = excludedUrls.some(url => req.url.startsWith(url + '/'));

          return hideLoader;
        }]
      }),
    ]),

  ]
};

