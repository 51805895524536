import { UserPermissions } from './../../../../../shared/src/lib/constants/permissions.constants';
import { Component, HostListener, OnInit, inject } from '@angular/core';
import { AuthService } from '../../pages/auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Observable, Subscription } from 'rxjs';
import { SharedEvents } from '../../../../../shared/src/lib/events';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent implements OnInit {
  isMobile: boolean = false;
  protected readonly authService = inject(AuthService)
  private readonly router = inject(Router)
  private loggedin: Observable<boolean>;

  constructor() {
    this.checkIfMobile();
  }

  ngOnInit(): void {

    this.validateAllItems();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      // Your code to execute after navigation ends goes here
      const closeButton = document.querySelector("#offcanvasNavbar > div.offcanvas-header > button") as HTMLButtonElement;
      if (closeButton) {
        closeButton.click();
      }

      this.validateAllItems();
    });


  }

  protected get canUseTheSystem() {
    return this.authService.userAccount?.canUseSystem;
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkIfMobile();
  }

  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth < 1024; // You can adjust this threshold as needed
    if (this.isMobile) {
      SharedEvents.ScreenIsMobileEvent.pushIsMobile()
    } else {
      SharedEvents.ScreenIsMobileEvent.pushIsNotMobile()
    }
  }


  validateAllItems() {
    this.validate_requestManagementItems();
  }


  protected logout() {
    this.authService.signOut();
  }

  get hasPropertyAdsPermission() {
    return this.authService.hasPermission([
      UserPermissions.Broker.PropertyAds_ViewAll, UserPermissions.TeamMember.PropertyAds_ViewAll,
      UserPermissions.TeamMember.PropertyAds_ViewBrokerAds
    ])
  }

  get hasTeamManagementPermission() {

    const teamMemberValidation = this.authService.isTeamMember() &&
      this.authService.hasPermission([
        UserPermissions.TeamMember.TeamManagement_ViewMembers,
        UserPermissions.TeamMember.TeamManagement_CreateMembers,
        UserPermissions.TeamMember.TeamManagement_EditMembers,
        UserPermissions.TeamMember.TeamManagement_DeleteMembers
      ]);

    if (teamMemberValidation) {
      return true;
    }



    return this.authService.isBroker() && this.authService.hasPermission([UserPermissions.Broker.TeamManagement_ManageTeam]);

  }

  protected requestManagementItems: Array<any> = [];
  validate_requestManagementItems() {
    this.requestManagementItems = [];


    const ClientsManagement_ViewAll = [UserPermissions.Broker.ClientsManagement_ViewAll, UserPermissions.TeamMember.ClientsManagement_ViewAll];
    if (this.authService.hasPermission(ClientsManagement_ViewAll)) {
      this.requestManagementItems.push({
        label: "clientManagement.clientsRequests",
        route: "/clients/requests/list"
      })
    }

    const InterestRequests_ViewAll = [UserPermissions.Broker.InterestRequests_ViewAll, UserPermissions.TeamMember.InterestRequests_ViewAll];
    if (this.authService.hasPermission(InterestRequests_ViewAll)) {
      this.requestManagementItems.push({
        label: "clientManagement.clientsInterestRequests",
        route: "/clients/requests/interests/list"
      })
    } else {
      console.warn(`Permission {${InterestRequests_ViewAll}} not exists`);

    }

    const SiteAdRequests_ViewAll = [UserPermissions.Broker.SiteAdRequests_ViewAll, UserPermissions.TeamMember.SiteAdRequests_ViewAll];
    if (this.authService.hasPermission(SiteAdRequests_ViewAll)) {
      this.requestManagementItems.push({
        label: "requestManagement.siteRequests",
        route: "/request-management/site-requests"
      })
    }
  }

  get hasClientProfilePermission() {
    return this.authService.hasPermission([
      UserPermissions.Broker.ClientsManagement_ViewAll_ClientProfile,
      UserPermissions.TeamMember.ClientsManagement_ViewAll_ClientProfile
    ])
  }
}


